import "./scss/style.scss";
// import ScrollMagic from "scrollmagic";
// import Header from "./js/template-parts/common/header";
import ModulesLoader from "./js/modules-loader";
// import Barba from "./js/lib/barba";
import Cookies from "./js/template-parts/popups/cookies";
import DisableScroll from "./js/lib/disable-scroll";
import BehaviourLauncher from "./js/template-parts/common/behaviourLauncher";
import { viewportHeightController } from "./js/template-parts/utils/jumble";
import MainMenu from "./js/template-parts/common/mainMenu";

// Load objects
document.addEventListener("DOMContentLoaded", () => {

	// BarbaJS
	// window.barba = new Barba();
});

window.addEventListener("load", () => {

	viewportHeightController();

	window.mainMenu = new MainMenu();
	window.behaviourLauncher = new BehaviourLauncher();

	// Ajax requests
	window.ajaxReq = function ajaxReq() {
		if (window.XMLHttpRequest) {
			return new XMLHttpRequest(); // eslint-disable-line
		}

		if (window.ActiveXObject) {
			return new ActiveXObject("Microsoft.XMLHTTP"); // eslint-disable-line no-console
		}

		console.log("Browser does not support XMLHTTP."); // eslint-disable-line no-console
		return false;
	};

	// Set animations
	// window.setAnimationScenes = function setAnimationScenes(
	// 	scrollMagicController
	// ) {
	// 	const animatableElements = [
	// 		".cards-slider",
	// 		".facts",
	// 		".featured-content",
	// 		".featured-links",
	// 		".grid-card",
	// 		".hero",
	// 		".locations",
	// 		".numbers",
	// 		".team",
	// 		".timeline",
	// 		".features",
	// 		".list__list",
	// 		".featured-text",
	// 		".text",
	// 		".floating-images",
	// 		".brands",
	// 	];
	// 	// Set animation classes to all the elements that will use them.
	// 	/* eslint-disable no-restricted-syntax */
	// 	for (const el of Array.from(
	// 		document.querySelectorAll(animatableElements.join(","))
	// 	)) {
	// 		new ScrollMagic.Scene({
	// 			triggerElement: el,
	// 			triggerHook: 0.75,
	// 			reverse: false,
	// 		})
	// 			.setClassToggle(el, "animated")
	// 			.addTo(scrollMagicController);
	// 	}
	// 	/* eslint-enable no-restricted-syntax */
	// };

	// Load JS scripts
	window.loadJS = function loadJS(file, callback) {
		// DOM: Create the script element
		const jsElm = document.createElement("script");
		// set the type attribute
		jsElm.type = "application/javascript";
		// make the script element load file
		jsElm.src = file;
		// add a callback
		jsElm.addEventListener("load", callback);
		// finally insert the element to the body element in order to load the script
		document.body.appendChild(jsElm);
	};

	// Disable scroll
	window.disableScroll = new DisableScroll();

	// Modules loader
	window.modulesLoader = new ModulesLoader();
	window.modulesLoader.loadModules();

	// Cookies banner
	window.cookies = new Cookies();

	// Animations
	// window.setAnimationScenes(new ScrollMagic.Controller());

	// Cerrar el modal de añadido al carrito al hacer clic en el botón "Entendido"
	if (document.getElementById('cerrar-modal')) {
		document.getElementById('cerrar-modal').addEventListener('click', function () {
			fadeOut('modal-overlay');
			fadeOut('producto-anadido-modal');
		});
	}

	preparaEventosDeActualizacionCarrito();

});

function preparaEventosDeActualizacionCarrito() {
	// Capturo el submit del formulario de pasar por caja para cuando se actualiza el carrito, que también
	// se actualice el número que aparece en el icono del carrito del menú
	if (document.querySelector('.woocommerce-cart-form')) {
		document.querySelector('.woocommerce-cart-form').addEventListener('submit', (e) => {
			console.log('submit');
			console.log(e);
			if (document.querySelector('#carrito_url').value != window.location.href) {
				e.preventDefault();
			}		
			setTimeout(actualizarContadorCarrito, 500);
		});
	}

	if (document.querySelector('.woocommerce-cart-form-lateral')) {
		document.querySelector('.woocommerce-cart-form-lateral').addEventListener('submit', (e) => {
			if (document.querySelector('#carrito_url').value != window.location.href) {
				e.preventDefault();
			}
			actualizarCarritoLateral();
//			setTimeout(actualizarContadorCarrito, 500);
		});
	}

	// Capturo el borrado de un artículo del carrito para cuando se elimine un elemento del carrito, que también
	// se actualice el número que aparece en el icono del carrito del menú
	if (document.querySelectorAll('.product-remove .remove')) {
		Array.prototype.forEach.call(document.querySelectorAll('.product-remove .remove'), (e) => {
			e.addEventListener('click', (e) => {
				console.log('remove');
				console.log(e);
				if (document.querySelector('#carrito_url').value != window.location.href) {
					e.preventDefault();
				}		
				setTimeout(actualizarContadorCarrito, 500);
			});

		});
	}

	// Agrega un evento al botón de cerrar para ocultar el carrito
	if (document.querySelector('.lateral-cart-close')) {
		document.querySelector('.lateral-cart-close').addEventListener('click', function (e) {
			e.preventDefault(); // Evita el comportamiento predeterminado del enlace
			document.querySelector('.lateral-cart').classList.add('hide'); // Añade la clase .hide para ocultar el div
		});
	}

	// Muestra el carrito nuevamente, remueve la clase .hide, si estamos en la página del carrito esta función no está activada
	if (document.querySelector('#showCartButton')) {
		Array.prototype.forEach.call(document.querySelectorAll('#showCartButton'), (e) => {
			e.addEventListener('click', function (e) {
				e.preventDefault(); // Evita el comportamiento predeterminado del enlace
				if (document.querySelector('#carrito_url').value != window.location.href) {
					document.querySelector('.lateral-cart').classList.remove('hide');
				}
			});
		});
	}
}

// Función para hacer fadeOut
function fadeOut(element) {
	// Obtenemos el elemento HTML
	let elementToFade = document.getElementById(element);
  
	// Iniciamos la animación, reduciendo la opacidad a 0 en 500ms (medio segundo)
	let opacity = 1;
	const intervalId = setInterval(() => {
		opacity -= 0.05;
		elementToFade.style.opacity = opacity;
		if (opacity <= 0) {
			clearInterval(intervalId);
			elementToFade.style.display = 'none';
		}
	}, 10);
}

function actualizarContadorCarrito() {
	var data = {
		action: 'obtener_numero_elementos_carrito', // Acción definida en PHP
	};

	let ajaxurl = document.querySelector('#ajax_url').value;

	// Hacer la solicitud AJAX
	fetch(ajaxurl, {
		method: 'POST',
		credentials: 'same-origin',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		},
		body: new URLSearchParams(data),
	})
	.then(response => response.json())
	.then(data => {
		if (data.success) {
			Array.prototype.forEach.call(document.querySelectorAll('.cart-count'), (e) => {
				if (0 == data.data) {
					e.remove();
				} else {
					e.innerHTML = data.data;
				}
			});
			preparaEventosDeActualizacionCarrito();
		} else {
			console.error('Error:', data.data);
		}
	})
	.catch(error => console.error('Error en la solicitud:', error));
}

function actualizarCarritoLateral() {
	// Crear un objeto con los datos a enviar
    let formData = new URLSearchParams();

	Array.prototype.forEach.call(document.querySelectorAll('.woocommerce-cart-form-lateral .quantity input'), (e) => {
		formData.append( e.getAttribute( 'name' ), e.value );
	});
	formData.append( 'coupon_code', '' );
	formData.append( 'woocommerce-cart-nonce',  document.querySelector('#woocommerce-cart-nonce').value );
	formData.append( '_wp_http_referer', '/cart/' );
	formData.append( 'update_cart', 'Update+Cart' );

	let ajaxurl = document.querySelector('#ajax_url').value;

    // Realizar la solicitud AJAX utilizando fetch
    fetch(ajaxurl, {
        method: 'POST',
        credentials: 'same-origin', // Para enviar cookies y mantener la sesión
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: formData.toString()
    })
    .then(response => response.text()) // Parsear la respuesta JSON
	.then(html => {
        // Parsear el HTML recibido
        let parser = new DOMParser();
        let doc = parser.parseFromString(html, 'text/html');

        // Seleccionar un elemento específico en el HTML, por ejemplo, .woocommerce-cart-form-lateral
        let cartFormElement = doc.querySelector('.woocommerce-cart-form-lateral');
        if (cartFormElement) {
			document.querySelector('.woocommerce-cart-form-lateral').innerHTML = cartFormElement.innerHTML;
        } else {
            console.log("El elemento .woocommerce-cart-form-lateral no se encontró en la respuesta.");
		}

		// Seleccionar un elemento específico en el HTML, por ejemplo, .woocommerce-cart-form-lateral
		cartFormElement = doc.querySelector('.cart_totals-lateral');
		if (cartFormElement) {
			document.querySelector('.cart_totals-lateral').innerHTML = cartFormElement.innerHTML;
		} else {
			console.log("El elemento .cart_totals-lateral no se encontró en la respuesta.");
		}
		
	})
    .catch(error => {
        console.error('Error en la solicitud:', error);
    });
}